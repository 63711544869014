import {SortOptions} from '../../types/pagination-config';

export const GlobalSortOptions: SortOptions[] = [
	{
		translateKey: 'SHARED.SORTERS.OLDEST',
		key: 'OLDEST',
		sort: 'date',
		order: 'Asc',
	},
	{
		translateKey: 'SHARED.SORTERS.NEWEST',
		key: 'NEWEST',
		sort: 'date',
		order: 'Desc',
	},
	{
		translateKey: 'SHARED.SORTERS.NAME_ASC',
		key: 'NAME_ASC',
		sort: 'name',
		order: 'Asc',
	},
	{
		translateKey: 'SHARED.SORTERS.NAME_DESC',
		key: 'NAME_DESC',
		sort: 'name',
		order: 'Desc',
	},
];
