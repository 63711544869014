import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '@core/modules/auth/services';

/**
 * Provides Guard to validate access to user logged routes
 */
@Injectable({providedIn: 'root'})
export class UserGuard {
	constructor(private authService: AuthService, private router: Router) {}

	/** Overrides canActivate to restrict routes
	 * This method redirect to login page view if user is not logged in,
	 * and returns boolean: true if the user is logged in, false otherwise
	 */
	canActivate(): boolean {
		if (!this.authService.isLoggedIn()) {
			this.router.navigate(['/login']);
		}
		if (this.authService.isLoggedIn() && this.authService.isAdminType()) {
			this.router.navigate(['/vidmizer-board']);
		}
		return this.authService.isLoggedIn();
	}
}
