import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {DatePipe, NgFor} from '@angular/common';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {MatMenu, MatMenuModule} from '@angular/material/menu';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {GlobalSortOptions} from '@shared/modules/videos/models/filters/sort-options.model';
import {TranslatePipe} from '@shared/pipes';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatDividerModule} from '@angular/material/divider';
import {MatBadgeModule} from '@angular/material/badge';

const MaterialModules = [
	MatIconModule,
	MatButtonModule,
	MatDividerModule,
	MatFormFieldModule,
	MatMenuModule,
	MatDatepickerModule,
	MatBadgeModule,
];

export type SortOptions = {
	translateKey: string;
	key: string;
	sort: 'name' | 'date';
	order: 'ASC' | 'Asc' | 'DESC' | 'Desc';
};

interface DateRange {
	start?: string | null;
	end?: string | null;
}

interface SortValue {
	active: SortOptions;
	range?: DateRange;
}

@Component({
	standalone: true,
	selector: 'green-sort-menu',
	imports: [NgFor, ReactiveFormsModule, ...MaterialModules, TranslatePipe],
	templateUrl: './sort-menu.component.html',
})
export class SortMenuComponent implements OnInit, OnChanges {
	@Input() range: DateRange;
	@Input() sortActive = GlobalSortOptions[1];

	@Output() sortChanged = new EventEmitter<SortValue>();

	@ViewChild('VideosSorters') VideosSorters: MatMenu;

	readonly sortOptions = GlobalSortOptions.map(x => x).sort((c, n) =>
		n.sort.localeCompare(c.sort)
	);

	private readonly datePipe = new DatePipe('fr-FR');
	rangeGroup = new FormGroup({
		start: new FormControl(),
		end: new FormControl(),
	});

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.sortActive) {
			this.sortActive = changes.sortActive.currentValue;
		}

		if (changes.range) {
			if (
				changes.range.currentValue.start === null &&
				changes.range.currentValue.end === null
			) {
				this.rangeGroup.reset();
			}

			this.range = changes.range.currentValue;
		}
	}

	ngOnInit(): void {
		this.rangeGroup.get('start')?.setValue(this.range.start);
		this.rangeGroup.get('end')?.setValue(this.range.end);
	}

	setActive(option: string): void {
		this.sortActive =
			this.sortOptions.find(c => c.key === option) ?? this.sortOptions[2];
		this.sort();
	}

	sort(): void {
		this.sortChanged.emit({
			active: this.sortActive,
			range: {start: this.formatDate('start'), end: this.formatDate('end')},
		});
		this.VideosSorters.closed.next();
	}

	private formatDate = (field: string): string | null =>
		this.datePipe.transform(this.rangeGroup.get(field)?.value, 'yyyy-LL-dd');
}
