import {Injectable} from '@angular/core';
import {UserView} from '@shared/modules/users/types/user-view.model';
import {User} from '@shared/modules/users/types/user';
import {LocalStore} from '@core/modules/storage';

const cookieFieldName = 'user';

/**
 * Provides Store to save, check, get or delete Users
 */
@Injectable({providedIn: 'root'})
export class UserStore {
	constructor(private localStore: LocalStore) {}
	saveUser = (input: User): UserView => {
		const user = new UserView(input);
		if (this.checkUser()) {
			this.clearUser();
		}
		this.localStore.set(cookieFieldName, input);
		return user;
	};

	checkUser = (): boolean => this.localStore.check(cookieFieldName);

	getUser = (): UserView | undefined =>
		this.checkUser()
			? JSON.parse(this.localStore.get(cookieFieldName))
			: undefined;

	clearUser = (): void => this.localStore.clear(cookieFieldName);
}
