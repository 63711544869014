import {ChangeDetectorRef, Pipe, PipeTransform} from '@angular/core';
import {TranslateService as CustomTranslateService} from '@core/modules/translation';
import { TranslateService  } from '@ngx-translate/core';

@Pipe({
	standalone: true,
	name: 'since',
})
export class SincePipe implements PipeTransform {
	private readonly translateRoute = 'UTILS.DURATIONS.';

	constructor(
		private customTranslator: CustomTranslateService,
		private readonly translator: TranslateService,
		private cd: ChangeDetectorRef
	) {
		customTranslator.onLangChange.subscribe(() => this.cd.detectChanges());
	}

	transform(value: any): string {
		if (value) {
			const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
			if (seconds < 29)
				// less than 30 seconds ago will show as 'Just now'
				return this.customTranslator.translate(this.translateRoute + 'NOW');
			const intervals: {[key: string]: number} = {
				year: 31536000,
				month: 2592000,
				week: 604800,
				day: 86400,
				hour: 3600,
				minute: 60,
				second: 1,
			};
			let counter;
			for (const i in intervals) {
				counter = Math.floor(seconds / intervals[i]);
				if (counter > 0) {
					const unit =
						' ' +
						this.customTranslator.translate(this.translateRoute + i.toUpperCase());
					const suffix =
						(i != 'month' || this.translator.currentLang != 'fr') && counter > 1
							? 's'
							: '';

					return this.customTranslator.translate({
						key: this.translateRoute + 'GRAMMAR',
						param: {
							duration: counter + unit + suffix,
						},
					});
				}
			}
		} else {
			return this.customTranslator.translate(this.translateRoute + 'NEVER');
		}
		return value;
	}
}
