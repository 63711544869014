import {Component, Inject} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {
	MatDialogRef,
	MAT_DIALOG_DATA,
	MatDialogModule,
} from '@angular/material/dialog';
import {TranslatePipe} from '@shared/pipes';

/**
 * Represents Message confirmation Dialog states
 */
export interface ConfirmationMessageData {
	title?: string;
	message: string;
	action?: string;
	cancel?: string;
}

/**
 * Dialog used to confirm action by user with custom message
 */
@Component({
	standalone: true,
	selector: 'green-confirmation-message',
	imports: [MatDialogModule, MatButtonModule, TranslatePipe],
	template: `<h2 mat-dialog-title class="centered is-primary">
			{{ data.title || ('SHARED.CONFIRMATION_NEEDED' | translate) }}
		</h2>
		<mat-dialog-content mat-dialog-content>
			<p class="centered spaced">{{ message }}</p>
		</mat-dialog-content>
		<mat-dialog-actions
			mat-dialog-actions
			class="spaced flex justify-evenly items-center">
			<button color="accent" mat-stroked-button (click)="close(false)">
				{{ data.cancel || ('BUTTONS.CANCEL' | translate) }}
			</button>
			<button color="primary" mat-raised-button (click)="close(true)">
				{{ data.action ?? ('BUTTONS.CONFIRM' | translate) }}
			</button>
		</mat-dialog-actions> `,
})
export class ConfirmationMessageComponent {
	get message(): string {
		return this.data.message;
	}

	constructor(
		private dialogRef: MatDialogRef<ConfirmationMessageComponent>,
		@Inject(MAT_DIALOG_DATA) protected readonly data: ConfirmationMessageData
	) {}

	close = (valid: boolean): void => this.dialogRef.close(valid);
}
