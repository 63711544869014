import {ErrorStateMatcher} from '@angular/material/core';
import {FormControl} from '@angular/forms';

export class ParentErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null): boolean {
		const controlTouched = !!(control && (control.dirty || control.touched));
		const controlInvalid = !!(control && control.invalid);
		const parentInvalid = !!(
			control &&
			control.parent &&
			control.parent.invalid &&
			(control.parent.dirty || control.parent.touched)
		);
		return controlTouched && (controlInvalid || parentInvalid);
	}
}
