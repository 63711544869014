import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@core/modules/translation/services/translate.service';

@Pipe({
	standalone: true,
	name: 'translate',
})
export class TranslatePipe implements PipeTransform {
	constructor(private translate: TranslateService) {}

	transform = (key: string, param?: {[param: string]: string}) =>
		this.translate.translate(param ? {key, param} : key);
}
