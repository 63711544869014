import {Account} from '../../accounts/types/account';

export interface User {
	uuid?: string;
	email?: string;
	firstName?: string;
	lastName?: string;
	password?: string;
	createdAt?: Date;
	updatedAt?: Date;
	isActive?: boolean;
	isConditionAgreed?: boolean;
	roles?: UserRole[];
	logo?: string;
	phone?: string;
	account?: Partial<Account>;
	isLoading?: boolean;
	isArchive?: boolean;
	lastConnection?: Date;
	theme?: Theme;
	lang?: Lang;
}

export enum Theme {
	DARK = 'DARK',
	LIGHT = 'LIGHT',
}
export enum Lang {
	FR = 'FR',
	EN = 'EN',
}

export enum UserRole {
	USER = 'ROLE_USER',
	VIDMIZER = 'ROLE_VIDMIZER',
	DEV = 'ROLE_DEV',
}
