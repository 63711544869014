import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	standalone: true,
	name: 'truncateText',
})
export class TruncateTextPipe implements PipeTransform {
	transform(
		value?: string | null,
		limit = 40,
		completeWords = false,
		trail = '…'
	): string {
		if (!value) {
			return '--';
		}
		if (value.length <= limit) {
			return `${value.substr(0, limit)}`;
		}
		if (completeWords) {
			limit = value.substr(0, limit).lastIndexOf(' ');
		}
		return `${value.substr(0, limit)}${trail}`;
	}
}
