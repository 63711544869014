import {FormGroup, FormBuilder} from '@angular/forms';
import {UserForm} from './user-form';
import {PasswordValidator} from '@core/modules/forms/models/password.validator';

export const PasswordRequiredForm = new FormGroup(
	{password: UserForm.password, confirm: UserForm.confirm},
	// @ts-ignore
	(formGroup: FormGroup) => PasswordValidator.areEqual(formGroup)
);

export const PasswordOptionalForm = new FormBuilder().group(
	{password: UserForm.optionalPassword, confirm: UserForm.optionalConfirm},
	{validator: PasswordValidator.passwordMatchValidator}
);

export const PasswordOnlyForm = new FormBuilder().group({
	password: UserForm.password,
});
