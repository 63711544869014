import {Token} from './token.model';

/**
 * Represents current User's information
 * from given Token
 */
export class AuthInfo {
	public readonly username!: string;
	public readonly roles!: string[];

	constructor(token?: Token) {
		if (token) {
			this.username = token.username;
			this.roles = token.roles;
		}
	}
}
