import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from 'ngx-mask';
import {FormFactory} from '.';

const modules = [
	FormsModule,
	ReactiveFormsModule,
	NgxMaskDirective,
	NgxMaskPipe,
];
@NgModule({
	imports: [...modules],
	exports: [...modules],
	providers: [FormFactory, provideNgxMask()],
})
export class FormModule {}
