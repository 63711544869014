import {
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {
	MatAutocompleteModule,
	MatAutocompleteSelectedEvent,
	MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import {NotificationService} from '@core/modules/notification';
import {AutocompleteOutput} from '../auto-complete.component';
import {NgFor, NgIf} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatChipsModule} from '@angular/material/chips';
import {TruncatedTextComponent} from '@shared/components/ui';
import {MatFormFieldModule} from '@angular/material/form-field';
import {TranslatePipe} from '@shared/pipes';
import {FormsModule} from '@angular/forms';

const MaterialModules = [
	MatButtonModule,
	MatIconModule,
	MatAutocompleteModule,
	MatFormFieldModule,
	MatChipsModule,
];

@Component({
	standalone: true,
	selector: 'green-input-auto-complete',
	imports: [
		NgIf,
		NgFor,
		FormsModule,
		...MaterialModules,
		TruncatedTextComponent,
		TranslatePipe,
	],
	templateUrl: './input-auto-complete.component.html',
})
export class InputAutoCompleteComponent implements OnChanges, OnInit {
	@ViewChild('valueInput') valueInput: ElementRef<HTMLInputElement>;
	@ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

	@Input() values: string[] = [];
	@Input() mode: 'menu' | 'input' | 'list' | 'menuItem';
	@Input() type: 'add' | 'search' | 'edit' | 'readonly';
	@Input() listValues: string[] | null = [];
	@Output() onChangedValues: EventEmitter<AutocompleteOutput> =
		new EventEmitter<AutocompleteOutput>();
	@Output() editView: EventEmitter<boolean> = new EventEmitter<boolean>();

	@HostBinding('class') class = 'inline-block';

	separatorKeysCodes: number[] = [ENTER, COMMA];
	valueCtrl = '';
	filteredValues: string[] | null;
	objectValue: AutocompleteOutput;
	canCloseAutocomplete = false;

	constructor(private notifier: NotificationService) {}

	ngOnInit(): void {
		this.setFilterValues();
	}

	valueCtrlOnChange(value: string): void {
		this.valueCtrl = value;
		this.setFilterValues();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.values) {
			this.objectValue = {
				current: Array.from(changes.values.currentValue ?? []),
				added: [],
				removed: [],
			};
		}
		if (changes.listValues) {
			this.listValues = changes.listValues.currentValue;
			this.setFilterValues();
		}
	}

	add(newValue: string): void {
		this.checkIfAlreadyAdded(newValue);
		this.valueCtrl = '';
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		const value = event.option.viewValue;
		this.checkIfAlreadyAdded(value);
		this.valueInput.nativeElement.value = '';
		this.valueCtrl = '';
		this.setFilterValues();
	}

	closeAutocomplete(): void {
		if (this.canCloseAutocomplete) {
			this.autocomplete.closePanel();
		}
		this.canCloseAutocomplete = !this.canCloseAutocomplete;
	}

	remove(value: string): void {
		if (this.objectValue.current) {
			const index = this.objectValue.current.indexOf(value);
			if (index >= 0 && this.objectValue.removed) {
				this.objectValue.current.splice(index, 1);
				this.objectValue.removed.push(value);
			}
		}
		this.autocomplete.closePanel();
		if (!this.objectValue.current.length) this.canCloseAutocomplete = true;
	}

	handleKeydownEnter(): void {
		if (!this.valueCtrl) {
			this.emitObjectValue();
			this.autocomplete.closePanel();
		} else {
			this.add(this.valueCtrl);
		}
	}

	emitObjectValue(): void {
		if (this.valueCtrl) {
			this.add(this.valueCtrl);
		}
		this.onChangedValues.emit({...this.objectValue});
		this.editView.emit(false);
	}

	resetObjectValue(): void {
		this.emptyArray();
		this.objectValue.current = Array.from(this.values ?? []);
		this.editView.emit(false);
	}

	clearAll(): void {
		this.objectValue.added = [];
		if (this.objectValue.removed && this.objectValue.current) {
			this.objectValue.removed = this.objectValue.removed.concat(
				this.objectValue.current
			);
		}
		this.objectValue.current = [];
	}

	private setFilterValues(): void {
		if (this.listValues?.length) {
			this.filteredValues = this.valueCtrl
				? this.filter(this.valueCtrl)
				: this.listValues.slice();
		}
	}

	private checkIfAlreadyAdded(value: string): void {
		if (value && this.objectValue.added && this.objectValue.current) {
			const index = this.objectValue.current.indexOf(value);
			if (index >= 0) {
				this.notifier.notify('tag already added');
			} else {
				this.canCloseAutocomplete = false;
				this.objectValue.current.push(value);
				this.objectValue.added.push(value);
			}
		}
		this.autocomplete.closePanel();
	}

	private emptyArray(): void {
		this.objectValue.added = [];
		this.objectValue.removed = [];
	}

	private filter = (currentValue: string): string[] | null => {
		const filterValue = currentValue.toLowerCase();
		return this.listValues
			? this.listValues.filter(value =>
					value.toLowerCase().includes(filterValue)
			  )
			: null;
	};
}
