// Required for Register / Login / Profile components
export const ErrorMessages = {
	user: {
		firstName: [
			{type: 'required', message: 'FORMS.USER.FIRST_NAME.ERRORS.REQUIRED'},
			{type: 'maxlength', message: 'FORMS.USER.FIRST_NAME.ERRORS.MAX_LENGTH'},
		],
		lastName: [
			{type: 'required', message: 'FORMS.USER.LAST_NAME.ERRORS.REQUIRED'},
			{type: 'maxlength', message: 'FORMS.USER.LAST_NAME.ERRORS.MAX_LENGTH'},
		],
		fullName: [
			{type: 'required', message: 'FORMS.USER.FULL_NAME.ERRORS.REQUIRED'},
			{type: 'maxlength', message: 'FORMS.USER.FULL_NAME.ERRORS.MAX_LENGTH'},
		],
		email: [
			{type: 'required', message: 'FORMS.USER.EMAIL.ERRORS.REQUIRED'},
			{type: 'pattern', message: 'FORMS.USER.EMAIL.ERRORS.BAD_FORMAT'},
			{
				type: 'isAlreadyEditor',
				message: 'FORMS.USER.EMAIL.ERRORS.ALREADY_EDITOR',
			},
		],
		phone: [
			{
				type: 'validatePhoneNumber',
				message: 'FORMS.USER.PHONE.ERRORS.BAD_FORMAT',
			},
			{type: 'required', message: 'FORMS.USER.PHONE.ERRORS.REQUIRED'},
		],
		password: [
			{type: 'required', message: 'FORMS.USER.PASSWORD.ERRORS.REQUIRED'},
			{type: 'minlength', message: 'FORMS.USER.PASSWORD.ERRORS.MIN_LENGTH'},
			{type: 'maxlength', message: 'FORMS.USER.PASSWORD.ERRORS.MAX_LENGTH'},
			{type: 'pattern', message: 'FORMS.USER.PASSWORD.ERRORS.BAD_FORMAT'},
		],
		confirm: [
			{type: 'pattern', message: 'FORMS.USER.CONFIRM.ERRORS.BAD_FORMAT'},
			{type: 'required', message: 'FORMS.USER.CONFIRM.ERRORS.REQUIRED'},
		],
		address: [
			{type: 'required', message: 'FORMS.USER.ADDRESS.ERRORS.REQUIRED'},
			{type: 'pattern', message: 'FORMS.USER.ADDRESS.ERRORS.BAD_FORMAT'},
		],
		postalCode: [
			{type: 'required', message: 'FORMS.USER.POST_CODE.ERRORS.REQUIRED'},
			{type: 'pattern', message: 'FORMS.USER.POST_CODE.ERRORS.BAD_FORMAT'},
		],
		country: [
			{type: 'required', message: 'FORMS.USER.COUNTRY.ERRORS.REQUIRED'},
			{type: 'pattern', message: 'FORMS.USER.COUNTRY.ERRORS.BAD_FORMAT'},
		],
		company: [
			{type: 'pattern', message: 'FORMS.USER.COMPANY.ERRORS.BAD_FORMAT'},
		],
		siret: [{type: 'pattern', message: 'FORMS.USER.KBIS.ERRORS.BAD_FORMAT'}],
		tva: [{type: 'pattern', message: 'FORMS.USER.TVA.ERRORS.BAD_FORMAT'}],
		message: [
			{type: 'pattern', message: 'FORMS.UTILS.MESSAGE.ERRORS.BAD_FORMAT'},
			{type: 'required', message: 'FORMS.UTILS.MESSAGE.ERRORS.REQUIRED'},
		],
		usage: [],
		terms: [{type: 'pattern', message: 'FORMS.USER.AGREE.ERRORS.BAD_FORMAT'}],
	},
	report: {
		totalCompletion: [
			{
				type: 'required',
				message: 'FORMS.REPORT.TOTAL_COMPLETION.ERRORS.REQUIRED',
			},
		],
		totalViews: [
			{type: 'required', message: 'FORMS.REPORT.TOTAL_VIEWS.ERRORS.REQUIRED'},
		],
		mobileCarbonWeight: [
			{
				type: 'required',
				message: 'FORMS.REPORT.MOBILE_CARBON_WEIGHT.ERRORS.REQUIRED',
			},
		],
		mobileRepartition: [
			{
				type: 'required',
				message: 'FORMS.REPORT.MOBILE_REPARTITION.ERRORS.REQUIRED',
			},
		],
		desktopCarbonWeight: [
			{
				type: 'required',
				message: 'FORMS.REPORT.DESKTOP_CARBON_WEIGHT.ERRORS.REQUIRED',
			},
		],
		desktopRepartition: [
			{
				type: 'required',
				message: 'FORMS.REPORT.DESKTOP_REPARTITION.ERRORS.REQUIRED',
			},
		],
	},
	folder: {
		name: [
			{type: 'required', message: 'FORMS.FOLDER.NAME.ERRORS.REQUIRED'},
			{type: 'maxlength', message: 'FORMS.FOLDER.NAME.ERRORS.MAX_LENGTH'},
		],
	},
	publication: {
		title: [
			{type: 'required', message: 'FORMS.PUBLICATION.TITLE.ERRORS.REQUIRED'},
			{type: 'maxlength', message: 'FORMS.PUBLICATION.TITLE.ERRORS.MAX_LENGTH'},
			{type: 'minlength', message: 'FORMS.PUBLICATION.TITLE.ERRORS.MIN_LENGTH'},
		],
		description: [
			{
				type: 'required',
				message: 'FORMS.PUBLICATION.DESCRIPTION.ERRORS.REQUIRED',
			},
			{
				type: 'maxlength',
				message: 'FORMS.PUBLICATION.DESCRIPTION.ERRORS.MAX_LENGTH',
			},
			{
				type: 'minlength',
				message: 'FORMS.PUBLICATION.DESCRIPTION.ERRORS.MIN_LENGTH',
			},
		],
	},
	pass_required: 'Your current password is required',
};
