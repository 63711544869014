import {NgModule} from '@angular/core';

import {APP_ROUTES} from './app.routes';
import {AppComponent} from './app.component';
import {CORE_MODULES} from './core';
import {RouterModule} from '@angular/router';
import {ToasterComponent} from '@core/modules/notification/components/toast.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';


@NgModule({
	declarations: [AppComponent],
	bootstrap: [AppComponent],
	imports: [
		...CORE_MODULES,
		RouterModule.forRoot(APP_ROUTES),
		ToasterComponent,
		StoreModule.forRoot({}),
    EffectsModule.forRoot(),
		// StoreDevtoolsModule.instrument({
    //   maxAge: 25, // Retains last 25 states
    //   autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    // }),
	],
})
export class AppModule {}
