<button
	color="primary"
	mat-stroked-button
	aria-label="library ordering filters"
	[matMenuTriggerFor]="VideosSorters">
	<mat-icon>sort</mat-icon>
	{{ sortActive.translateKey | translate }}
</button>
<mat-menu #VideosSorters="matMenu" xPosition="before">
	<button
		mat-menu-item
		class="menu-item"
		*ngFor="let opt of sortOptions"
		(click)="setActive(opt.key)">
		<span>{{ opt.translateKey | translate }}</span>
	</button>
	<mat-divider />
	<span
		class="spaceless"
		mat-menu-item
		(click)="$event.stopPropagation(); picker.open()"
		(keydown)="$event.stopPropagation(); picker.open()">
		<mat-form-field appearance="fill">
			<mat-label>{{
				'VIEWS.USER_BOARD.LIBRARY.SORT_OPTIONS.DATE_RANGE' | translate
			}}</mat-label>
			<mat-date-range-input [formGroup]="rangeGroup" [rangePicker]="picker">
				<input
					matStartDate
					formControlName="start"
					placeholder="Start date"
					[value]="range.start" />
				<input
					matEndDate
					formControlName="end"
					placeholder="End date"
					[value]="range.end" />
			</mat-date-range-input>
			<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
			<mat-date-range-picker #picker>
				<mat-date-range-picker-actions>
					<button
						mat-button
						matDateRangePickerCancel
						(click)="rangeGroup.reset(); sort()">
						{{ 'BUTTONS.CANCEL' | translate }}
					</button>
					<button
						mat-raised-button
						color="primary"
						(click)="sort()"
						matDateRangePickerApply>
						{{ 'BUTTONS.CONFIRM' | translate }}
					</button>
				</mat-date-range-picker-actions>
			</mat-date-range-picker>
		</mat-form-field>
	</span>
</mat-menu>
