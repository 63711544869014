import {NgClass, NgFor, NgIf} from '@angular/common';
import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule, MatMenuTrigger} from '@angular/material/menu';
import {InputAutoCompleteComponent} from './input-auto-complete/input-auto-complete.component';
import {TranslatePipe} from '@shared/pipes';
import {MatBadgeModule} from '@angular/material/badge';
import {MatChipsModule} from '@angular/material/chips';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TruncatedTextComponent} from '@shared/components/ui';

export interface AutocompleteOutput {
	current: string[];
	removed?: string[];
	added?: string[];
}

const MaterialModules = [
	MatButtonModule,
	MatIconModule,
	MatMenuModule,
	MatBadgeModule,
	MatChipsModule,
	MatTooltipModule,
];

@Component({
	standalone: true,
	selector: 'green-auto-complete',
	imports: [
		NgIf,
		NgFor,
		NgClass,
		...MaterialModules,
		InputAutoCompleteComponent,
		TranslatePipe,
		TruncatedTextComponent,
	],
	templateUrl: './auto-complete.component.html',
	styleUrls: ['./auto-complete.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class AutoCompleteComponent implements OnChanges {
	@ViewChild(MatMenuTrigger, {static: false}) menu: MatMenuTrigger;

	@Input() values?: string[] = [];
	@Input() mode: 'menu' | 'input' | 'list' | 'menuItem';
	@Input() type: 'add' | 'search' | 'edit' | 'readonly';
	@Input() chipLimit = 3;
	@Input() badge? = true;
	@Input() noLabel? = false;
	@Input() disabled? = false;
	@Input() outline? = false;
	@Input() hashtag? = false;
	@Input() listValues: string[] | null = [];

	@Output() onChangedValues: EventEmitter<AutocompleteOutput> =
		new EventEmitter<AutocompleteOutput>();

	editView = false;
	hiddenTags = 0;
	objectValues: AutocompleteOutput = {current: []};

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.values && this.values) {
			this.values = [...new Set(changes.values.currentValue)] as string[];
			this.hiddenTags = changes.values.currentValue.length - this.chipLimit;
		}
		if (changes.listValues) {
			this.listValues = changes.listValues.currentValue;
		}
	}

	toggleView(): void {
		this.editView = !this.editView;
	}

	valuesChanged(values: AutocompleteOutput): void {
		if (this.menu) {
			this.menu.closeMenu();
		}
		this.values = values.current;
		this.objectValues = values;
		this.onChangedValues.emit(values);
	}
}
