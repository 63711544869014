import {JsonPipe, NgIf} from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	HostBinding,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';

@Component({
	standalone: true,
	selector: 'green-lazy-img',
	imports: [NgIf, JsonPipe],
	template: `<svg
			*ngIf="isLoading"
			id="skeleton"
			class="w-full h-auto px-1 fill-slate-600 bg-slate-200"
			viewBox="-18 0 100 100">
			<path
				d="m61 10h-58a2 2 0 0 0 -2 2v40a2 2 0 0 0 2 2h58a2 2 0 0 0 2-2v-40a2 2 0 0 0 -2-2zm0 2v33.587l-21.293-21.294a1 1 0 0 0 -1.414 0l-9.293 9.293-5.293-5.293a1 1 0 0 0 -1.414 0l-19.293 19.293v-35.586zm-58 40v-1.586l20-20 11.293 11.293a1 1 0 0 0 1.414-1.414l-5.293-5.293 8.586-8.586 22 22v3.586z" />
			<path
				d="m37.707 42.293a1 1 0 0 0 -1.414 1.414l1 1a1 1 0 0 0 1.414-1.414z" />
			<path
				d="m23 25a5 5 0 1 0 -5-5 5.006 5.006 0 0 0 5 5zm0-8a3 3 0 1 1 -3 3 3 3 0 0 1 3-3z" />
			<path d="m45 16h1a1 1 0 0 0 0-2h-1a1 1 0 0 0 0 2z" />
			<path d="m49 16h9a1 1 0 0 0 0-2h-9a1 1 0 0 0 0 2z" />
		</svg>
		<img
			class="w-full h-full object-contain"
			*ngIf="src && (!isLoading || !isError)"
			[src]="src"
			(load)="hideLoader()"
			(error)="error()"
			[alt]="alt" />
		<span class="hidden">{{ src | json }}</span>`,
})
export class LazyImgComponent implements OnChanges {
	@Input() src: string;
	@Input() alt = '';

	@HostBinding('class') class =
		'inline-block w-full aspect-video overflow-hidden rounded-lg bg-black';

	isError = false;
	isLoading: boolean;

	constructor(private cdRef: ChangeDetectorRef) {
		this.isLoading = true;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.src) {
			this.isLoading = true;
			this.src = changes.src.currentValue;
			this.cdRef.detectChanges();
		}
	}

	hideLoader(): void {
		this.isLoading = false;
	}

	error(): void {
		this.isError = true;
	}
}
