<mat-card
	[title]="title ?? '' | translate"
	class="mx-auto w-fit px-5 max-w-full">
	<mat-card-header *ngIf="title || subtitle" class="text-primary !pt-8 !pb-2">
		<mat-card-title class="text-center !text-2xl" *ngIf="!!title">
			{{ title | translate }}
		</mat-card-title>
		<mat-card-subtitle
			class="text-center text-balance !text-lg"
			*ngIf="!!subtitle">
			{{ subtitle | translate }}
		</mat-card-subtitle>
	</mat-card-header>

	<mat-card-content
		class="relative !p-4 !flex flex-col items-center justify-start"
		style="min-width: 200px; min-height: 50px">
		<ng-content></ng-content>
	</mat-card-content>

	<mat-card-actions *ngIf="!!cardFooter" class="justify-evenly !px-2 !pb-8">
		<ng-container [ngTemplateOutlet]="cardFooter"></ng-container>
	</mat-card-actions>
</mat-card>
