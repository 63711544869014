import {Account} from '@shared/modules/accounts/types';
import {Lang, Theme, User, UserRole} from './user';
import {AccountRole} from '@core/modules/auth/types/whoami';

export type RestrictedRoutes =
	| 'dashboard'
	| 'profile'
	| 'video_library'
	| 'video_details'
	| 'video_encode'
	| 'report_encode'
	| 'report_config'
	| 'account_invoice'
	| 'account_payment';

export type RestrictedActions =
	| 'video_download'
	| 'video_encode'
	| 'video_delete'
	| 'video_recode'
	| 'account_invite'
	| 'move_folder'
	| 'player'
	| 'report_config';

export class UserView {
	readonly uuid?: string;
	readonly initials: string;
	readonly email?: string;
	readonly firstName?: string;
	readonly lastName?: string;
	readonly logo?: string;
	readonly roles?: UserRole[];
	readonly phone?: string;
	readonly isConditionAgreed: boolean;

	readonly createdAt?: Date;
	readonly updatedAt?: Date;
	readonly isArchive: boolean;
	readonly isAdmin: boolean;
	readonly accountRole?: AccountRole;
	readonly isAccountAdmin: boolean;
	readonly isEditor: boolean;
	readonly savedAt?: Date;
	readonly theme?: Theme;
	readonly lang?: Lang;

	account?: Partial<Account>;

	constructor(user?: User | UserView) {
		if (user) {
			this.uuid = user.uuid;
			this.firstName = user.firstName;
			this.lastName = user.lastName;
			this.initials = this.formatInitials(user);
			this.email = user.email;
			this.roles = user.roles;
			this.logo = user.logo;
			this.theme = user.theme;
			this.lang = user.lang;
			if (user.phone) {
				this.phone = this.formatPhone(user.phone);
			}
			this.isConditionAgreed = !!user.isConditionAgreed;
			this.roles = user.roles;

			this.account = user.account;

			this.createdAt = user.createdAt;
			this.updatedAt = user.updatedAt;
			this.isArchive = !!user.isArchive;
			if (user.roles) {
				this.isAdmin = [UserRole.DEV, UserRole.VIDMIZER].includes(
					user.roles[0]
				);
			}
			this.accountRole = user.account?.accountRole;
			this.isAccountAdmin = user.account?.accountRole?.code === 'admin';
			this.isEditor = user.account?.accountRole?.code === 'editor';

			this.savedAt = new Date();
		}
	}

	canDo = (
		right: RestrictedActions | RestrictedRoutes,
		folderRole?: 'editor' | 'reader'
	) => this.can(right, folderRole);

	canAccess = (route: RestrictedRoutes, folderRole?: 'editor' | 'reader') =>
		this.can(route, folderRole);

	private can = (
		right: RestrictedActions | RestrictedRoutes,
		folderRole?: 'editor' | 'reader'
	) => {
		const targetRole = folderRole ?? this.accountRole?.code ?? 'reader';
		return (
			(folderRole === 'editor' &&
				this.account?.accountRoles?.editor.includes(right)) ||
			this.isAccountAdmin ||
			this.isAdmin ||
			this.accountRole?.rights.map(r => r.code).includes(right)
		);
	};

	private formatInitials(user: User | UserView): string {
		if (user.firstName) {
			if (user.lastName) {
				return (
					user.firstName.charAt(0) + user.lastName.charAt(0)
				).toLocaleUpperCase();
			} else {
				return user.firstName.charAt(0).toLocaleUpperCase();
			}
		} else {
			return user.email?.charAt(0) ?? '👽';
		}
	}

	private formatPhone = (phone: string): string =>
		phone.length === 9 ? '0' + phone : phone;
}
