import {Injectable} from '@angular/core';
import {AccountPreview} from '@core/modules/auth/types/whoami';
import {LocalStore} from '@core/modules/storage';

const cookieFieldName = 'user_accounts';

/**
 * Provides Store to save, check, get or delete Accounts
 */
@Injectable({providedIn: 'root'})
export class AccountStore {
	constructor(private localStore: LocalStore) {}

	saveAccounts = (input: AccountPreview[]): AccountPreview[] => {
		if (this.checkAccounts()) {
			this.clearAccounts();
		}
		const toStore = input
			.map<any>(account => {
				account.company ??= account.email;
				return account;
			})
			.sort((a, b) => a.company.localeCompare(b.company));
		this.localStore.set(cookieFieldName, toStore);
		return input;
	};

	checkAccounts = (): boolean => this.localStore.check(cookieFieldName);

	getAccounts = (): AccountPreview[] =>
		JSON.parse(this.localStore.get(cookieFieldName));

	clearAccounts = (): void => this.localStore.clear(cookieFieldName);
}
