import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';

/*
  Provides methods to handle different kind of Dialog on the application
 */
@Injectable({providedIn: 'root'})
export class DialogService {
	confirm(message?: string): Observable<boolean> {
		const confirmation = window.confirm(message || 'Are you sure?');
		return of(confirmation);
	}
}
