import {Component, HostBinding} from '@angular/core';

@Component({
	standalone: true,
	selector: 'green-loader',
	template: `<span></span>`,
	styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
	@HostBinding('class') class =
		'is-primary absolute inset-0 bg-slate-500/15 flex items-center justify-center';
}
