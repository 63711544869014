import {NgModule} from '@angular/core';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {DialogService} from './services/dialog.service';

@NgModule({
	imports: [MatDialogModule],
	exports: [MatDialogModule],
	providers: [DialogService, {provide: MatDialogRef, useValue: {}}],
})
export class DialogModule {}
