import {Validators, FormControl} from '@angular/forms';
import {Patterns} from '@core/modules/forms/models/patterns';

export const UserForm = {
	name: new FormControl(
		'',
		Validators.compose([Validators.minLength(1), Validators.maxLength(255)])
	),
	lastName: new FormControl(
		'',
		Validators.compose([Validators.minLength(1), Validators.maxLength(255)])
	),
	email: new FormControl(
		'',
		Validators.compose([
			Validators.required,
			Validators.maxLength(255),
			Validators.pattern(Patterns.email),
		])
	),
	password: new FormControl(
		'',
		Validators.compose([
			Validators.required,
			Validators.minLength(6),
			Validators.maxLength(25),
			Validators.pattern(Patterns.password),
		])
	),
	confirm: new FormControl(
		'',
		Validators.compose([
			Validators.required,
			Validators.minLength(6),
			Validators.maxLength(25),
			Validators.pattern(Patterns.password),
		])
	),
	phone: new FormControl('', []),
	country: new FormControl(
		'',
		Validators.compose([Validators.minLength(1), Validators.maxLength(255)])
	),
	postalCode: new FormControl(
		'',
		Validators.compose([Validators.minLength(1), Validators.maxLength(255)])
	),
	address: new FormControl(
		'',
		Validators.compose([Validators.minLength(1), Validators.maxLength(255)])
	),
	usages: ['', [Validators.required]],
	agree: new FormControl(false, Validators.requiredTrue),
	siret: new FormControl(
		'',
		Validators.compose([Validators.pattern(Patterns.alphanumeric)])
	),
	company: new FormControl(
		'',
		Validators.compose([Validators.minLength(1), Validators.maxLength(255)])
	),
	tva: new FormControl(
		'',
		Validators.compose([Validators.minLength(1), Validators.maxLength(255)])
	),
	logo: new FormControl(''),
	optionalPassword: new FormControl(
		'',
		Validators.compose([
			Validators.minLength(6),
			Validators.maxLength(25),
			Validators.pattern(Patterns.password),
		])
	),
	optionalConfirm: new FormControl(
		'',
		Validators.compose([
			Validators.minLength(6),
			Validators.maxLength(25),
			Validators.pattern(Patterns.password),
		])
	),
};
