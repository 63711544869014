import {NgModule} from '@angular/core';
import {NotificationService} from './services/notification.service';
import {MatSnackBarModule} from '@angular/material/snack-bar';

@NgModule({
	imports: [MatSnackBarModule],
	exports: [MatSnackBarModule],
	providers: [NotificationService],
})
export class NotificationModule {}
