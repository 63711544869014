import {
	Component,
	EventEmitter,
	HostBinding,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
	ViewEncapsulation,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';

const MaterialModules = [MatFormFieldModule, MatInputModule, MatIconModule];

@Component({
	standalone: true,
	selector: 'green-input-search',
	imports: [...MaterialModules, FormsModule, ReactiveFormsModule],
	template: `<mat-form-field class="no-padding flex-1">
		<input
			matInput
			[placeholder]="label"
			type="search"
			[(ngModel)]="searchValue"
			(keyup.enter)="searchEvent.emit(searchValue)" />
		<mat-icon
			matSuffix
			[color]="isLoading ? 'null' : 'primary'"
			(click)="!isLoading && searchEvent.emit(searchValue)">
			search
		</mat-icon>
	</mat-form-field>`,
	styleUrls: ['./input-search.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class InputSearchComponent implements OnChanges {

	@Input() isLoading = true;
	@Input() label = '';
	@Input() initialSearchValue = '';

	@Output() searchEvent = new EventEmitter<string>();

	@HostBinding('class') class =
		'flex justify-center items-start h-11 flex-1 max-w-sm mx-8 box-border';

	searchValue = this.initialSearchValue;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.initialSearchValue) {
			this.searchValue = changes.initialSearchValue.currentValue;
		}
	}
}
