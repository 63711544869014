import {NgFor, NgIf} from '@angular/common';
import {
	Component,
	EventEmitter,
	Input,
	Output,
	QueryList,
	ViewChildren,
	WritableSignal,
	signal,
} from '@angular/core';
import {MatOption, MatOptionModule} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule, MatMenuTrigger} from '@angular/material/menu';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TruncatedTextComponent} from '@shared/components/ui';
import {ClickOutsideDirective} from '@shared/directives/click-outside.directive';
import {EncodingConfigViewDto} from '@shared/modules/encoding-config/types/encoding-config';
import {BehaviorSubject} from 'rxjs';
import {delay, filter, map} from 'rxjs/operators';
import * as uuid from 'uuid';

@Component({
	selector: 'green-option-group',
	standalone: true,
	imports: [
		MatOptionModule,
		MatIconModule,
		MatMenuModule,
		MatTooltipModule,
		NgIf,
		NgFor,
		TruncatedTextComponent,
		ClickOutsideDirective
	],
	templateUrl: './option-group.component.html',
})
export class OptionGroupComponent {
	@ViewChildren('option') options: QueryList<MatOption>;

	@Input() unlimited = '';
	@Input() groups: any[] = [];
	@Input() multiple = false;
	@Input() set canExpand(canExpand: number) {
		this.hasClickedOutside.set(true);
		this.resetExpand(this._prevContext.label);
	};
	
	@Input() set clickOutside(clickOutside: boolean) {
		if (this._prevContext.label && clickOutside) {
			this.hasClickedOutside.set(true);
			this.resetExpand(this._prevContext.label)
		}
	}

	@Input() set encodingConfing(config: EncodingConfigViewDto[]) {
		if (!config) return;
		this.groups = this.getGroups(config);
	}

	@Input() set selected(selectedElements: any[]) {
		this.selectedElements.set(selectedElements);
	}

	@Output() selectedOptions = new EventEmitter<string | string[]>();

	public isExpanded = signal<{[name: string]: boolean}>({});
	public selectedElements = signal<any[]>([]);
	public hasClickedOutside = signal<boolean>(false);
	public canExpandToggle = 1;
	
	private _prevContext: {trigger: MatMenuTrigger | undefined, label: string};
	private _prevSelectedOption!: WritableSignal<MatOption | null>;
	private _optionSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

	constructor() {
		this._prevContext = {trigger: undefined, label: ''};

		this._optionSubject
			.pipe(
				delay(90),
				map(() => document.getElementsByClassName('mat-mdc-menu-panel')),
				filter(menus => !!menus.length),
			)
			.subscribe(menus => {
				// Deselect options removed from outside this select
				this.options.forEach(option => {
					if (
						!this.selectedElements()
							.map(e => e.name)
							.includes(option.value)
					) {
						option.deselect();
					}
				});

				// Get last menu bottom and right limits
				const lastMenu = menus.item(menus.length - 1) as HTMLElement;
				const menuBottom = lastMenu.getBoundingClientRect().bottom;
				const menuRight = lastMenu.getBoundingClientRect().right;
				
				// Get window bottom and right limits
				const bodyHeight = document.body.clientHeight;
				const bodyWidth = document.body.clientWidth;

				if (menuRight > bodyWidth) {
					this.moveMenu(lastMenu, menuRight, bodyWidth, 'left');

				} else if (menuBottom > bodyHeight) {
					this.moveMenu(lastMenu, menuBottom, bodyHeight, 'top');
				}
			})
	}

	// Set menu tree
	private getGroups(data: EncodingConfigViewDto[]) {
		const groups: any[] = [];

		data.forEach(row => {
			const labels = row.chemin.split('/');
			const lastLabel = labels[labels.length - 1];
			const firstLabel = labels[0];

			let group = this.getGroup(groups, firstLabel);

			labels.forEach(label => {
				if (group.label === label) {
					this.setOption(group, label, lastLabel, row.config);
				} else {
					if (!group.groups) group.groups = [];
					group = this.getGroup(group.groups, label);
					this.setOption(group, label, lastLabel, row.config);
				}
			});
		});

		return groups;
	}

	private setOption(
		group: any,
		label: string,
		lastLabel: string,
		options: any
	) {
		if (label !== lastLabel) return;

		if (!group.options) group.options = [options];
		else group.options.push(options);
	}

	private getGroup(groups: any[], label: string) {
		let group = groups.find(g => g.label === label);

		if (!group) {
			group = {label};
			groups.push(group);
		}

		return group;
	}

	// Menu interactions
	moveMenu(menu: HTMLElement, menuLimit: number, windowLimit: number, position: 'top' | 'left') {
		let diff = windowLimit - menuLimit - 10;
		diff = position === 'left' ? diff + 185 : diff;
		menu.style.setProperty('position', 'absolute');
		menu.style.setProperty(position, diff + 'px');
	}

	manageOptions() {
		this._optionSubject.next(true);
	}

	switchTrigger(currentTrigger: MatMenuTrigger, currentLabel: string, clickOutside: boolean = false) {
		if (!currentTrigger) return;
		
		const {trigger, label} = this._prevContext;
		this.hasClickedOutside.set(clickOutside);
		
		if (trigger && trigger.menuOpen && label && label !== currentLabel) {;
			this.hasClickedOutside.set(true);
			trigger.closeMenu();
		}
		this._prevContext.trigger = currentTrigger;
		this._prevContext.label = currentLabel;
	}

	// Manage menu expansion
	toggleExpand(label: string) {
		if (this.canExpandToggle || label !== this._prevContext.label) {
			this.isExpanded()[label] = !this.isExpanded()[label];
		}
		this.canExpandToggle += 1;
	}

	resetExpand(label: string) {
		if (!this.hasClickedOutside()) this.canExpandToggle = 0;
		else this.canExpandToggle += 1;
		this.isExpanded()[label] = false;
	}

	// Select values
	selectUnlimitedOptions(option: MatOption, element: any) {
		option.deselect();
		const newElement = {...element};
		newElement.optionUuid = uuid.v4();
		this.selectedElements().push(newElement);
		this.selectedOptions.emit(this.selectedElements());
	}

	selectMultipleOptions(option: MatOption, element: any) {
		if (this.selectedElements().find(e => e.name === option.value)) {
			option.deselect();
			this.selectedElements.set(
				this.selectedElements().filter(e => e.name !== option.value)
			);
		} else {
			element.optionUuid = uuid.v4();
			this.selectedElements().push(element);
		}

		this.selectedOptions.emit(this.selectedElements());
	}

	selectUniqueOption(option: MatOption, element: any) {
		if (this._prevSelectedOption) {
			this._prevSelectedOption()?.deselect();
		}

		this.selectedOptions.emit(element);

		if (!this._prevSelectedOption) {
			this._prevSelectedOption = signal(option);
		} else if (this._prevSelectedOption()?.value !== option.value) {
			this._prevSelectedOption.set(option);
		} else {
			this._prevSelectedOption.set(null);
		}
	}

	selectOption(
		option: MatOption,
		element: any,
		label: string,
		event: MouseEvent
	) {
		if (this.unlimited.toLowerCase() === label.toLowerCase()) {
			this.selectUnlimitedOptions(option, element);
			// prevent the menu from closing
			event.stopPropagation();
		} else if (this.multiple) {
			this.selectMultipleOptions(option, element);
			// prevent the menu from closing
			event.stopPropagation();
		} else {
			this.toggleExpand(label);
			this.selectUniqueOption(option, element);
		}
	}
}
