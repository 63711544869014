import {AuthModule} from './modules/auth/auth.module';
import {HttpModule} from './modules/http/http.module';
import {PlatformModule} from './modules/platform/platform.module';
import {StorageModule} from './modules/storage/storage.module';
import {TranslateModule} from './modules/translation/translate.module';

import {DialogModule} from './modules/dialog/dialog.module';
import {NotificationModule} from './modules/notification/notification.module';
import {FormModule} from './modules/forms/form.module';

export {
	AuthModule,
	HttpModule,
	PlatformModule,
	StorageModule,
	TranslateModule,
	DialogModule,
	NotificationModule,
	FormModule,
};
export const CORE_MODULES = [
	StorageModule,
	PlatformModule,
	HttpModule,
	TranslateModule,
	AuthModule,
	DialogModule,
	NotificationModule,
	FormModule,
];
