<ng-template #content>
	<ng-content></ng-content>
	<ng-container *ngIf="label">{{ label | translate }}</ng-container>
</ng-template>

<ng-container *ngIf="href; else buttonTemplate" [ngSwitch]="type">
	<a
		[routerLink]="href"
		[fragment]="fragment"
		[queryParams]="params"
		*ngSwitchCase="'raised'"
		mat-raised-button
		[color]="color"
		[disabled]="disabled">
		<ng-container *ngTemplateOutlet="content"></ng-container>
	</a>

	<a
		[routerLink]="href"
		[queryParams]="params"
		[fragment]="fragment"
		*ngSwitchCase="'stroked'"
		mat-stroked-button
		[color]="color"
		[disabled]="disabled">
		<ng-container *ngTemplateOutlet="content"></ng-container>
	</a>

	<a
		[routerLink]="href"
		[queryParams]="params"
		[fragment]="fragment"
		*ngSwitchCase="'flat'"
		mat-flat-button
		[color]="color"
		[disabled]="disabled">
		<ng-container *ngTemplateOutlet="content"></ng-container>
	</a>

	<a
		[routerLink]="href"
		[queryParams]="params"
		[fragment]="fragment"
		*ngSwitchCase="'icon'"
		mat-icon-button
		[color]="color"
		[disabled]="disabled">
		<ng-container *ngTemplateOutlet="content"></ng-container>
	</a>

	<a
		[routerLink]="href"
		[queryParams]="params"
		[fragment]="fragment"
		*ngSwitchCase="'menu-item'"
		mat-menu-item
		[class]="'text-' + color"
		[disabled]="disabled">
		<ng-container *ngTemplateOutlet="content"></ng-container>
	</a>

	<a
		*ngSwitchDefault
		mat-button
		[routerLink]="href"
		[queryParams]="params"
		[fragment]="fragment"
		[color]="color"
		[disabled]="disabled">
		<ng-container *ngTemplateOutlet="content"></ng-container>
	</a>
</ng-container>

<ng-template #buttonTemplate [ngSwitch]="type">
	<button
		*ngSwitchCase="'raised'"
		mat-raised-button
		[color]="color"
		[disabled]="disabled">
		<ng-container *ngTemplateOutlet="content"></ng-container>
	</button>

	<button
		*ngSwitchCase="'stroked'"
		mat-stroked-button
		[color]="color"
		[disabled]="disabled">
		<ng-container *ngTemplateOutlet="content"></ng-container>
	</button>

	<button
		*ngSwitchCase="'flat'"
		mat-flat-button
		[color]="color"
		[disabled]="disabled">
		<ng-container *ngTemplateOutlet="content"></ng-container>
	</button>

	<button
		*ngSwitchCase="'icon'"
		mat-icon-button
		[color]="color"
		[disabled]="disabled">
		<ng-container *ngTemplateOutlet="content"></ng-container>
	</button>

	<button
		*ngSwitchCase="'menu-item'"
		mat-menu-item
		[class]="'text-' + color"
		[disabled]="disabled">
		<ng-container *ngTemplateOutlet="content"></ng-container>
	</button>

	<button *ngSwitchDefault mat-button [color]="color" [disabled]="disabled">
		<ng-container *ngTemplateOutlet="content"></ng-container>
	</button>
</ng-template>
