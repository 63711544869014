import {NgIf} from '@angular/common';
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslatePipe} from '@shared/pipes';

@Component({
	selector: 'green-video-rating',
	standalone: true,
	imports: [NgIf, TranslatePipe],
	templateUrl: 'rating.component.html',
	styleUrls: ['rating.component.scss'],
})
export class RatingComponent {
	@Input() rated: number | null;

	@Output() starRating = new EventEmitter<number>();

	onItemChange(evt: any) {
		this.starRating.emit(evt.target.value);
	}
}
