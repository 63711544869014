import {
	Component,
	Input,
	Output,
	EventEmitter,
	ViewChild,
	OnChanges,
	SimpleChanges,
	AfterViewInit,
	ViewEncapsulation,
} from '@angular/core';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {MatSelect, MatSelectModule} from '@angular/material/select';

import {NgFor, NgIf} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';

import {debounce} from 'rxjs/operators';
import {interval} from 'rxjs';
import {MatIconModule} from '@angular/material/icon';
import {TranslatePipe} from '@shared/pipes';
import {MatBadgeModule} from '@angular/material/badge';

const MaterialModules = [
	MatButtonModule,
	MatIconModule,
	MatBadgeModule,
	MatSelectModule,
];

type Option = {translateKey: string; key: string; selected: boolean};

@Component({
	standalone: true,
	selector: 'green-option-selector',
	imports: [
		NgIf,
		NgFor,
		ReactiveFormsModule,
		...MaterialModules,
		TranslatePipe,
	],
	templateUrl: './option-selector.component.html',
	styleUrls: ['./option-selector.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class OptionSelectorComponent implements OnChanges, AfterViewInit {
	@Input() disabled = false;
	@Input() action: string;
	@Input() options: Option[];

	@Output() onSelectionChange = new EventEmitter();

	@ViewChild('matSelectRef') matSelectRef: MatSelect;

	optionsControl = new FormControl();

	ngAfterViewInit(): void {
		this.matSelectRef?.selectionChange
			.pipe(debounce(() => interval(850)))
			.subscribe(() =>
				this.onSelectionChange.emit({
					value: this.optionsControl.value,
					options: this.formatSelectedOptions(),
				})
			);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.options) {
			this.optionsControl.setValue(
				(changes.options.currentValue ?? [])
					.filter((c: Option) => c.selected)
					.map((c: Option) => c.key)
			);
		}
	}

	resetOptions(): void {
		this.options = this.options.map(c => {
			c.selected = false;
			return c;
		});
	}

	clear = (): void =>
		this.matSelectRef.options.forEach(data => data.deselect());

	private formatSelectedOptions = () => {
		return this.options.map(c => {
			c.selected = this.optionsControl.value.includes(c.key);
			return c;
		});
	};
}
