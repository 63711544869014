/**
 * Interface to receive Token valid fields only
 */

export interface IToken {
	readonly code: number;
	readonly token?: string;
	readonly isConditionAgreed?: boolean;
	readonly error?: string;
}

/**
 * Represents current User's token / identifier based
 * on given object matching IToken interface
 */
export class Token {
	public readonly token!: string;
	public readonly username: string;
	public readonly roles: string[];

	constructor(token: any) {
		this.token = token.token;
		this.username = token.username;
		this.roles = token.roles;
	}
}
