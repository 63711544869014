import {NgClass, NgFor, NgIf} from '@angular/common';
import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {ThemePalette} from '@angular/material/core';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {TranslatePipe} from '@shared/pipes';
import {DurationPipe, FileSizePipe} from '@shared/pipes';

export type BannerInformation = {
	name: string;
	value?: any;
	icon: string;
	type?: 'size' | 'duration' | 'date';
	color: ThemePalette;
};

@Component({
	standalone: true,
	selector: 'green-banner',
	imports: [
		NgFor,
		NgIf,
		NgClass,
		MatCardModule,
		MatProgressBarModule,
		MatIconModule,
		DurationPipe,
		FileSizePipe,
		TranslatePipe,
	],
	template: `<mat-card
		style="box-shadow: none; background: transparent"
		class="flex-1 !flex !flex-row flex-wrap items-center justify-between !rounded-2xl">
		<div
			class="flex items-center justify-center flex-1 h-full w-56 py-2"
			*ngFor="let info of infos">
			<mat-icon class="sm !text-stroke"  >{{ info.icon }}</mat-icon>
			<div class="ml-4">
				<h4 class="is-noe-display mb-2 dark:!text-smoke text-sm disabled">
					{{ info.name | translate }}
				</h4>
				<strong  class="text-3xl is-noe-display !text-stroke">
					<span *ngIf="!info.type">{{ info.value ?? '--' }}</span>
					<span *ngIf="info.type === 'duration'">
						{{ info.value ?? 0 | duration }}
					</span>
					<span *ngIf="info.type === 'size'">
						{{ info.value ?? 0 | filesize }}
					</span>
				</strong>
			</div>
		</div>
		<mat-progress-bar *ngIf="!infos" mode="indeterminate" color="accent" />
	</mat-card>`,
})
export class BannerComponent implements OnChanges {
	@Input({required: true}) infos: BannerInformation[];

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.infos) {
			this.infos = changes.infos.currentValue;
		}
	}
}
