import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class JWTService {
	parseJwt = (token?: string): any | null => {
		if (token) {
			try {
				return JSON.parse(atob(token.split('.')[1]));
			} catch (e) {
				return null;
			}
		}
		return null;
	};
}
