import {Injectable} from '@angular/core';
import {JWTService} from '../jwt.service';
import {IToken, Token} from '@core/modules/auth/types/token.model';
import {CookieStore} from '@core/modules/storage';

const cookieFieldName = 'greencoder_Token';

/**
 * Provides Store to save, check, get or delete tokens
 */
@Injectable({providedIn: 'root'})
export class TokenStore {
	constructor(private jwt: JWTService, private cookie: CookieStore) {}

	saveToken = (input: IToken): Token => {
		const decoded = this.jwt.parseJwt(input.token);
		const token = new Token({
			token: input.token,
			username: decoded.username,
			roles: decoded.roles,
		});
		if (this.cookie.check(cookieFieldName)) {
			this.clearToken();
		}
		this.cookie.set(
			cookieFieldName,
			input.token ?? '',
			decoded.exp,
			'',
			'',
			true
		);
		return token;
	};

	checkToken = (): boolean => this.cookie.check(cookieFieldName);

	getToken = (): Token | undefined =>
		this.checkToken()
			? new Token(this.jwt.parseJwt(this.cookie.get(cookieFieldName)))
			: undefined;

	getTokenRaw = (): string | undefined =>
		this.checkToken() ? this.cookie.get(cookieFieldName) : undefined;

	clearToken = (): void => this.cookie.delete(cookieFieldName);
}
