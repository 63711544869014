import {NgIf, NgStyle} from '@angular/common';
import {Component, Input} from '@angular/core';

@Component({
	standalone: true,
	selector: 'green-svg-donut',
	imports: [NgIf, NgStyle],
	templateUrl: './svg-donut.component.html',
	styleUrls: ['./svg-donut.component.scss'],
})
export class SVGDonutComponent {
	@Input() percent?: string | number = 0;
	@Input() unit = '';
	@Input() color: string;

	get percentDisplay(): string {
		return this.percent
			? +this.percent <= 0
				? '--'
				: this.percent.toString()
			: '--';
	}
	get unitDisplay(): string {
		return typeof this.percent === 'number' && this.percent > 0
			? this.unit
			: '';
	}

	get percentHue(): number {
		if (typeof this.percent === 'number') {
			return this.percent > 100 ? 100 : this.percent < 0 ? 0 : this.percent;
		}
		return 0;
	}

	get colorStyle(): string {
		return this.color || 'hsl(' + this.percentHue + ', 75%, 50%)';
	}
}
