import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '@core/modules/auth/services';
import {UserRole} from '../../../../shared/modules/users/types/user';

/**
 * Provides Guard to validate access to admin logged routes
 */
@Injectable({providedIn: 'root'})
export class AdminGuard {
	constructor(private authenticator: AuthService, private router: Router) {}

	/** Overrides canActivate to restricted routes
	 * This method redirect to home page view if user is not logged in,
	 * and returns boolean: true if the user is logged in, false otherwise
	 */
	canActivate(): boolean {
		const isAdmin =
			this.authenticator.hasRole(UserRole.VIDMIZER) ||
			this.authenticator.hasRole(UserRole.DEV);

		if (!this.authenticator.isLoggedIn()) {
			this.router.navigate(['/login']).then();
			return false;
		}

		if (!isAdmin) {
			this.router.navigate(['/app/dashboard']).then();
		}

		return isAdmin;
	}
}
