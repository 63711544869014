<div class="flex items-center justify-start gap-1">
	<span
		class="h-5 text-start dark:!text-{{darkModeColor}}"
		[ngClass]="{ 'overflow-hidden': !uploadDialog }"
		[style]="{'max-width': length + 'ch'}"
		*ngIf="!toggleInput"
		[matTooltipDisabled]="value.length < length"
		[matTooltip]="value">
		{{ value | truncateText : length }}
	</span>
	<button
		mat-icon-button
		color="primary"
		*ngIf="!toggleInput"
		(click)="toggleView()">
		<mat-icon style="font-size: smaller;">edit</mat-icon>
	</button>
	<div *ngIf="toggleInput" class="flex justify-evenly items-center gap-2">
		<input
			matInput
			class="non-editable dark:!text-white !bg-inherit"
			[ngClass]="{editable: toggleInput}"
			[disabled]="!toggleInput"
			[(ngModel)]="value"
			(keyup.enter)="send(value)" />
		<div class="flex items-center justify-center gap-3">
			<button
				color="accent"
				mat-icon-button
				aria-label="reset selection"
				(click)="reset()">
				<mat-icon>replay</mat-icon>
			</button>
			<button
				color="primary"
				mat-icon-button
				aria-label="confirm selection"
				(click)="send(value)">
				<mat-icon>check</mat-icon>
			</button>
		</div>
	</div>
</div>
