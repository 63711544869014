/**
 * Represents current User's information
 * when creating new account.
 */

export class RegisterInfo {
	private readonly email: string;
	private readonly password: string;
	private readonly usages: string;

	constructor(email: string, password: string, usage: string) {
		this.email = email;
		this.password = password;
		this.usages = usage;
	}
}
