import { Pipe, PipeTransform } from '@angular/core'; 
  
@Pipe({ 
    standalone: true,
    name: 'pretty'
}) 
  
export class PrettyJsonPipe implements PipeTransform { 
  
    transform(value: any, indent: number = 2): string {
        if (!value) {
          return '';
        }
        try {
          // Stringify JSON with proper indentation
          const jsonString = JSON.stringify(value, null, indent);
    
          // Colorize keys, values, and symbols using a replace function
          return jsonString
            .replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
            .replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
              (match) => {
                let cls = 'number';
                if (/^"/.test(match)) {
                  cls = /:$/.test(match) ? 'key' : 'string';
                } else if (/true|false/.test(match)) {
                  cls = 'boolean';
                } else if (/null/.test(match)) {
                  cls = 'null';
                }
                return `<span class="${cls}">${match}</span>`;
              }
            );
        } catch (error) {
          console.error('Invalid JSON', error);
          return 'Invalid JSON';
        }
      }
}