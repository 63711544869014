import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	standalone: true,
	name: 'weight',
})
export class WeightPipe implements PipeTransform {
	transform(
		value?: number | null,
		inputUnit: 'g' | 'kg' | 't' = 'g',
		outputUnit: 'g' | 'kg' | 't' = 'kg'
	): string | void {
		if (!value) {
			return `0 ${outputUnit}`;
		}
		let result = '--';
		switch (inputUnit) {
			case 'g':
				result = this.outputGram(value, outputUnit);
				break;
			case 'kg':
				result = this.outputKiloGramn(value, outputUnit);
				break;
			case 't':
				result = this.outputTon(value, outputUnit);
				break;
			default:
				break;
		}
		return result;
	}

	outputGram = (value: number, outputUnit: string): string => {
		let result = '--';
		if (outputUnit === 'kg') {
			result = (value / 1_000).toFixed(3).toString() + ' Kg';
		}
		if (outputUnit === 't') {
			result = (value / 1_000_000).toFixed(3).toString() + ' T';
		}
		return result;
	};

	outputKiloGramn = (value: number, outputUnit: string): string => {
		let result = '--';
		if (outputUnit === 'g') {
			result = (value * 1_000).toFixed(3).toString() + ' g';
		}
		if (outputUnit === 't') {
			result = (value / 1_000).toFixed(3).toString() + ' T';
		}
		return result;
	};

	outputTon = (value: number, outputUnit: string): string => {
		let result = '--';
		if (outputUnit === 'g') {
			result = (value / 1_000_000).toFixed(3).toString() + ' g';
		}
		if (outputUnit === 'kg') {
			result = (value / 1_000).toFixed(3).toString() + ' Kg';
		}
		return result;
	};
}
